var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "notification", staticClass: "notification-box tw-px-5" },
    [
      _c("div", { staticClass: "notification" }, [
        _c("div", { staticClass: "notification-header" }, [
          _c("h3", { staticClass: "mb-0 bold" }, [_vm._v("Notifications")]),
          _c("p", { staticClass: "mb-0 d-block text-right" }, [
            _c(
              "a",
              {
                staticClass: "text-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.markAllAsRead()
                  },
                },
              },
              [_vm._v("Mark All as Read")]
            ),
          ]),
        ]),
        _c(
          "ul",
          { staticClass: "notification-body" },
          _vm._l(_vm.notifications, function (notification, i) {
            return _c(
              "li",
              {
                key: i,
                on: {
                  click: function ($event) {
                    return _vm.markAsRead(notification.id, notification)
                  },
                },
              },
              [
                _c(
                  "a",
                  {
                    class: [
                      "notification-content",
                      notification.timestampread ? "" : "active",
                    ],
                  },
                  [
                    _c("b-avatar", {
                      attrs: {
                        src: notification.profileUrl
                          ? notification.profileUrl
                          : "./android-chrome-192x192.png",
                        size: "50px",
                      },
                    }),
                    _c("div", { staticClass: "notification-text" }, [
                      _c("h4", { staticClass: "mb-1" }, [
                        _c("span", { staticClass: "bold" }, [
                          _vm._v(_vm._s(notification.title) + " "),
                        ]),
                      ]),
                      _c("span", { staticClass: "mb-0" }, [
                        _vm._v(" " + _vm._s(notification.description) + " "),
                      ]),
                      _c("small", { staticClass: "d-block" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.moment
                                .unix(notification.timestampcreated)
                                .format("MMMM DD, YYYY")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }