<template>
  <div class="notification-box tw-px-5" ref="notification">
    <!-- <v-icon name="bell" size="20" :class="ping ? 'active' : ''" @click="toggle" style="cursor: pointer"></v-icon> -->
    <div class="notification">
      <div class="notification-header">
        <h3 class="mb-0 bold">Notifications</h3>
        <p class="mb-0 d-block text-right">
          <a href="#" class="text-primary" v-on:click.prevent="markAllAsRead()"
            >Mark All as Read</a
          >
        </p>
      </div>
      <ul class="notification-body">
        <li
          v-for="(notification, i) in notifications"
          :key="i"
          v-on:click="markAsRead(notification.id, notification)"
        >
          <a
            :class="[
              'notification-content',
              notification.timestampread ? '' : 'active',
            ]"
          >
            <b-avatar
              :src="
                notification.profileUrl
                  ? notification.profileUrl
                  : './android-chrome-192x192.png'
              "
              size="50px"
            ></b-avatar>
            <div class="notification-text">
              <h4 class="mb-1">
                <span class="bold">{{ notification.title }} </span>
              </h4>
              <span class="mb-0">
                {{ notification.description }}
              </span>

              <small class="d-block">
                {{
                  moment
                    .unix(notification.timestampcreated)
                    .format("MMMM DD, YYYY")
                }}
              </small>
            </div>
          </a>
        </li>
      </ul>
      <!-- <div class="notification-footer">View all Notifications</div> -->
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

import { mapGetters } from "vuex";
import { NotificationService } from "@/services/notification.service";

export default {
  name: "notification",
  data() {
    return {
      nextPage: 1,
      show: false,
      ping: true,
      unread: 0,
    };
  },
  methods: {
    markAsRead(id, notification) {
      this.$store
        .dispatch("dashboard/markAsRead", id)
        .then(({ data }) => {
          notification.timestampread = this.moment.unix();
          this.unread -= 1;
        })
        .catch((error) => error);
    },
    markAllAsRead() {
      this.$store
        .dispatch("dashboard/markAllAsRead")
        .then(({ data }) => {
          this.unread = 0;
        })
        .catch((error) => error);
    },
    async notificationScroll(e) {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.documentElement.scrollHeight
      ) {
        if (this.nextPage) {
          const { data: notifications } =
            await NotificationService.getNotifications(this.nextPage);
          for (let i = 0; i < notifications.items.length; i++) {
            let notification = notifications.items[i];
            this.notifications.push(notification);
            if (!notification.timestampread) {
              this.unread += 1;
            }
          }

          if (notifications.nextpage) {
            this.nextPage = notifications.nextpage;
          } else {
            this.nextPage = null;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      notifications: "dashboard/getNotifications",
    }),
  },
  async mounted() {
    window.analytics.page();
    this.nextPage = null;
    this.$store
      .dispatch("dashboard/getNotifications", this.nextPage)
      .then((res) => {
        this.nextPage = res.nextpage;
        for (let i = 0; i < this.notifications.length; i++) {
          let notification = this.notifications[i];
          if (!notification.timestampread) {
            this.unread += 1;
          }
        }
      });
    window.addEventListener("scroll", this.notificationScroll);

    // to close the notification outside
    // document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    // for reduce memory consumption we need to remove it after you close the notification
    // document.removeEventListener("click", this.close);
    window.removeEventListener("scroll", this.notificationScroll);
  },
};
</script>

<style scoped>
/deep/.dropdown-menu {
  padding: 0;
  height: 500px;
  overflow: auto;
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
$class: ".notification";

#{$class}-header,
#{$class}-body > li {
  width: 65%;
  border-bottom: 1px solid rgba($border-color, 0.05);
}

@media (max-width: 768px) {
  #{$class}-header,
  #{$class}-body > li {
    width: 100%;
  }
}

#{$class}-box {
  position: relative;
  user-select: none;

  #{$class} {
    padding-top: 2rem;

    #{$class}-header {
      padding: 10px 0;
      margin: 0 auto;
    }

    #{$class}-body {
      // max-height: calc(100vh - 105px - 3.5rem);
      // overflow: auto;
      // overflow-x: hidden;
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-flow: column;
      align-items: center;

      > li {
        a {
          padding: 10px 20px;
          color: inherit;
          cursor: pointer;
        }

        #{$class}-content {
          display: flex;
          align-items: center;

          .b-avatar {
            flex: 50px 0 0;
            margin-right: 1.25rem;
          }

          &.active {
            background-color: $quaternary;
            // color: $white;

            // p {
            //   color: $white;
            // }
          }
        }
      }
    }

    #{$class}-footer {
      background-color: #e8f0f7;
      text-align: center;
      padding: 10px;
    }
  }
}

// @media (min-width: 768px) {
//   #{$class}-box {
//     #{$class} {
//       position: absolute;
//       width: 360px;
//       top: auto;
//       right: 0;
//       left: auto;
//       z-index: 1030;

//       #{$class}-body {
//         max-height: 500px;
//       }
//     }
//   }
// }
</style>
